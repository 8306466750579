import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchBidLimitBalanceIfNeeded } from './bidLimit';
import { fetchLiveCatalogStatusIfNeeded } from './liveCatalogStatus';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getCatalogSellerId } from './catalog/catalogs/catalog.selectors';
import { getDeployment } from './config';
import { getPlacedLiveBidAnalytics } from './analytics';
import {
    LIVE_BID_ACCEPTED,
    LIVE_LOT_CLOSED,
    LIVE_LOT_PASSED,
    LIVE_LOT_SKIPPED,
    LIVE_LOT_SOLD,
    LIVE_NEXT_LOT_LOADED,
    LiveBidAcceptedAction,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    OPEN_PLACE_ABSENTEE_BID,
} from './actions';
import { Location } from 'react-router-dom';
import { postPlaceLiveBid } from '@/redux/modules/retractBid/retractBid.api';
import { ROUTER_LOCATION_CHANGE } from '@/types/router/routerLocationChange';
import { TypedActionWithPayload } from '@/types/redux';

/* Action Types */
export const LIVE_BID_FAIL = 'la/ui/liveBid/FAIL';
type LiveBidFailAction = TypedActionWithPayload<
    typeof LIVE_BID_FAIL,
    {
        error: boolean;
        isServerError: boolean;
        isUserError: boolean;
    }
>;
export const LIVE_BID_REQUEST = 'la/ui/liveBid/REQUEST';
export const LIVE_BID_SUCCESS = 'la/ui/liveBid/SUCCESS';

export type LiveBidSlice = {
    bidderHasHighBid: boolean;
    currentBidderSuccess: boolean;
    error: boolean;
    hasServerError: boolean;
    hasUserError: boolean;
    itemId?: number;
    submitted: boolean;
    success: boolean;
};

// reducer
export const defaultLiveBidSlice: LiveBidSlice = {
    bidderHasHighBid: false,
    currentBidderSuccess: false,
    error: false,
    hasServerError: false,
    hasUserError: false,
    itemId: undefined,
    submitted: false,
    success: false,
};

export default function reducer(state: LiveBidSlice = defaultLiveBidSlice, action: any = {}): LiveBidSlice {
    switch (action.type) {
        case LIVE_BID_REQUEST:
            return {
                ...state,
                currentBidderSuccess: false,
                hasServerError: false,
                hasUserError: false,
                itemId: action.payload.itemId,
                submitted: true,
                success: false,
            };
        case LIVE_BID_ACCEPTED:
            const { payload } = action as LiveBidAcceptedAction;
            return {
                ...state,
                bidderHasHighBid: payload.myBid,
                currentBidderSuccess: payload.isCurrentBidder,
                hasServerError: false,
                hasUserError: false,
                itemId: undefined,
                submitted: false,
                success: true,
            };
        case LIVE_LOT_CLOSED:
        case LIVE_LOT_PASSED:
        case LIVE_LOT_SKIPPED:
        case LIVE_LOT_SOLD:
            return {
                ...state,
                bidderHasHighBid: false,
                currentBidderSuccess: false,
                hasServerError: false,
                hasUserError: false,
                itemId: undefined,
                submitted: false,
                success: true,
            };
        case LIVE_BID_FAIL:
            const typedAction: LiveBidFailAction = action;
            const { isServerError, isUserError } = typedAction.payload;
            return {
                ...state,
                currentBidderSuccess: false,
                error: true,
                hasServerError: isServerError,
                hasUserError: isUserError,
                itemId: undefined,
                submitted: false,
                success: false,
            };
        case LIVE_NEXT_LOT_LOADED:
            return {
                ...state,
                bidderHasHighBid: false,
                currentBidderSuccess: false,
                hasServerError: false,
                hasUserError: false,
                itemId: undefined,
                submitted: false,
            };
        case LOAD_LIVE_CATALOG_STATUS_SUCCESS:
            const currentHighBidderId = action.payload?.currentItem?.leadingBidder;
            return {
                ...state,
                bidderHasHighBid: action.meta.bidderId === currentHighBidderId,
                currentBidderSuccess: false,
            };
        case OPEN_PLACE_ABSENTEE_BID:
            return {
                ...state,
                currentBidderSuccess: false,
                hasServerError: false,
                hasUserError: false,
                submitted: false,
                success: false,
            };
        case ROUTER_LOCATION_CHANGE:
            return defaultLiveBidSlice;
        default:
            return state;
    }
}

/* SELECTORS */
const passThroughSelector = <PassedThroughType>(_: GlobalState, id: PassedThroughType): PassedThroughType => id;
const stateSelector = (state: GlobalState) => state;
export const getUiLiveBid = createSelector(stateSelector, (state) => state.liveBid);

export const getBidderHasHighBid = createSelector(getUiLiveBid, (state) => state.bidderHasHighBid);
export const getBidderSubmittedBid = createSelector(getUiLiveBid, (state) => state.submitted);
export const getBidderSubmittedBidId = createSelector(getUiLiveBid, (state) => state.itemId);
export const getLiveBidSuccessfulSubmission = createSelector(getUiLiveBid, ({ success }) => success);
export const getCurrentBidderPlaceBidSuccessfulSubmission = createSelector(
    getUiLiveBid,
    ({ currentBidderSuccess }) => currentBidderSuccess
);

export const getLiveBidHasError = createSelector(getUiLiveBid, ({ error }) => error);
export const getLiveBidHasServerError = createSelector(getUiLiveBid, ({ hasServerError }) => hasServerError);
export const getLiveBidHasUserError = createSelector(getUiLiveBid, ({ hasUserError }) => hasUserError);

const getIsMobileHack = createSelector(
    (state: any) => state.browser,
    (state: any) => state.mobile
);

type BidSource = '' | 'MobileItem' | 'DesktopItem' | 'MobileWeb' | 'DesktopWeb';
export const getBidSource = createSelector(
    [getIsMobileHack, passThroughSelector],
    (mobile: string, location: Location): BidSource => {
        const { pathname } = location;

        let bidSource: BidSource = '';
        if (pathname.includes('item')) {
            if (mobile) {
                bidSource = 'MobileItem';
            } else {
                bidSource = 'DesktopItem';
            }
        } else {
            if (mobile) {
                bidSource = 'MobileWeb';
            } else {
                bidSource = 'DesktopWeb';
            }
        }
        return bidSource;
    }
);

/* ACTIONS CREATORS */
export const submitLiveBid =
    (
        actionTime: number,
        catalogId: number,
        currentAskPrice: number,
        currency: string,
        itemId: number,
        location: Location
    ) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const created = new Date(actionTime).toISOString();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const sellerId = getCatalogSellerId(state, catalogId);
            const bidderId = getBidderId(state);
            const source = getBidSource(state, location);

            dispatch({
                payload: {
                    amount: currentAskPrice,
                    catalogId,
                    created,
                    currency,
                    itemId,
                },
                type: LIVE_BID_REQUEST,
            });

            if (!authToken || !itemId || !catalogId || !currentAskPrice || bidderId < 1) {
                throw new Error('Failed to submit bid, missing critical information');
            }

            await dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, sellerId));

            await postPlaceLiveBid({
                authToken,
                bidAmount: currentAskPrice,
                catalogId,
                deployment,
                itemId,
                sellerId,
                source,
            });

            // currently this is used only for the analytics
            const analytics = getPlacedLiveBidAnalytics(state, itemId, currentAskPrice, location);
            dispatch({
                meta: { analytics },
                payload: {
                    actionTime,
                    amount: currentAskPrice,
                    catalogId,
                    created,
                    currency,
                },
                type: LIVE_BID_SUCCESS,
            });
            // Fetch the bid limit balance every time we place a bid. (maybe this can get returned from the bid api someday)
            return dispatch(fetchBidLimitBalanceIfNeeded({ catalogId, itemId }));
        } catch (error) {
            let isUserError = false;
            let isServerError = true;
            if (typeof error === 'number' && (error === 406 || error === 422)) {
                isUserError = true;
                isServerError = false;
            }
            const failAction: LiveBidFailAction = {
                error: true,
                payload: {
                    error: true,
                    isServerError,
                    isUserError,
                },
                type: LIVE_BID_FAIL,
            };
            dispatch(failAction);

            const state = getState();
            const sellerId = getCatalogSellerId(state, catalogId);
            const bidderId = getBidderId(state);

            // Log error
            console.error('bidding-console: failed to place bid', {
                extra: {
                    bidderId,
                    catalogId,
                    currentAskPrice,
                    error: `${error}`,
                    itemId,
                    sellerId,
                },
                tags: {
                    eventType: 'Placed Bid',
                },
            });

            // Refetch live auction state because console is likely out of sync with current ask price of live item
            await dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, sellerId, true));
        }
    };
