import { $Values } from 'utility-types';
import { AddressModalData } from '@/types/address/address.types';
import { AnalyticsTypesValues, EventContext, trackGetHelpViewed } from './analytics';
import { AppDispatch } from '@/redux/store';
import {
    COMPLETE_ACCOUNT_SUCCESS,
    COMPLETE_LOGIN_MODAL,
    DISMISS_MODAL,
    OPEN_ADD_CREDIT_CARD_MODAL,
    OPEN_BID_LIMIT_EXCEEDED_MODAL,
    OPEN_BUY_NOW_INQUIRY_MODAL,
    OPEN_BUY_NOW_MODAL,
    OPEN_CATALOG_ANNOUNCEMENT_MODAL,
    OPEN_CHANGE_PASSWORD_MODAL,
    OPEN_CLASSIC_BIDDING_CONSOLE_MODAL,
    OPEN_CLEAR_CACHE_MODAL,
    OPEN_COMPLETE_ACCOUNT_MODAL,
    OPEN_COMPLETE_ACCOUNT_SIGNUP_MODAL,
    OPEN_CONFIRM_UNFOLLOW_SEARCH_MODAL,
    OPEN_CONSIGNMENT_CONFIRM_MODAL,
    OPEN_DELETE_ACCOUNT_CONFIRMATION_MODAL,
    OPEN_DOWNLOAD_MOBILE_MODAL,
    OPEN_EXCLUDE_HOUSE_MODAL,
    OPEN_FEEDBACK_MODAL,
    OPEN_HEALTHCHECK_MODAL,
    OPEN_INSTALL_FLASH_MODAL,
    OPEN_LIVE_BID_MODAL,
    OPEN_LIVE_SHIPPING_DEADLINE_MODAL,
    OPEN_MODAL,
    OPEN_ON_THE_HUNT_MODAL,
    OPEN_PHONE_VERIFICATION_MODAL,
    OPEN_PLACE_ABSENTEE_BID,
    OPEN_PURCHASE_PROTECTION_MODAL,
    OPEN_REGISTER_FOR_CATALOG_MODAL,
    OPEN_REPORT_REVIEW_MODAL,
    OPEN_RESEND_CONFIRMATION_EMAIL_MODAL,
    OPEN_RETRACT_BID_MODAL,
    OPEN_REVIEWS_MODAL,
    OPEN_SEND_SELLER_CONSIGNMENT_MESSAGE_MODAL,
    OPEN_SEND_SELLER_MESSAGE_MODAL,
    OPEN_SEND_SELLER_PHOTO_REQUEST_MODAL,
    OPEN_SHIPPING_AND_PAYMENT_MODAL,
    OPEN_SMS_CONFIRMATION_MODAL,
    OPEN_TAX_EXEMPT_MODAL,
    OPEN_WAITING_FOR_APPROVAL_MODAL,
    OpenCatalogAnnouncementModalAction,
    SET_LOGIN_SIGNUP_TYPE,
    SET_MODAL_DATA,
} from './actions';
import { CREATE_ACCOUNT_SUCCESS } from './account/createAccount/createAccount.types';
import { fetchSellersIfNeeded } from '@/redux/modules/seller';
import { FORGOT_PASSWORD_FAIL } from './forgotPassword';
import { GetHelpModalTypes } from '@/components/Modals/GetHelp/enums/GetHelpModal.enum';
import { LOGIN_SUCCESS } from '@/redux/modules/account/login/login.types';
import { modalTypes, PaymentProviders } from '@/enums';
import { PhotoRequestType } from '@/components/Modals/SendSellerPhotoRequest/types/SendSellerPhotoRequest';
import { ROUTER_LOCATION_CHANGE } from '@/types/router/routerLocationChange';
import { SendSellerMessageSlice } from './sendSellerMessage';

export type LoginSignupTypes =
    | 'default'
    | 'exit intent - item'
    | 'exit intent - price result'
    | 'exit intent - search'
    | 'see sold price'
    | 'see similar items'
    | 'follow auctioneer'
    | 'forgot password'
    | 'register for auction'
    | 'register for auction - item'
    | 'save item'
    | 'place bid'
    | 'ask a question - auctioneer'
    | 'ask a question - item'
    | 'follow search'
    | 'request more photos'
    | 'request a better photo'
    | 'join'
    | 'login'
    | 'login and run function';

export enum CompleteAccountModalContext {
    AskQuestionAuctioneer = 'ask a question - auctioneer',
    AskQuestionItem = 'ask a question - item',
    Default = 'default',
    ExitIntentItem = 'exit intent - item',
    ExitIntentPriceResult = 'exit intent - price result',
    ExitIntentSearch = 'exit intent - search',
    FollowAuctioneer = 'follow auctioneer',
    FollowSearch = 'follow search',
    ForgotPassword = 'forgot password',
    Join = 'join',
    Login = 'login',
    LoginAndRunFunction = 'login and run function',
    PlaceBid = 'place bid',
    RegisterForAuction = 'register for auction',
    RegisterForAuctionItem = 'register for auction - item',
    RequestBetterPhoto = 'request a better photo',
    RequestMorePhotos = 'request more photos',
    SaveItem = 'save item',
    SeeSimilarItems = 'see similar items',
    SeeSoldPrice = 'see sold price',
}

export type ModalContext = {
    /** catalogId to be used in segment tracking */
    catalogId?: number;
    /** segment tracking eventContext enum for context of eventName */
    eventContext?: EventContext;
    /** segment tracking eventName type of triggering event */
    eventName?: AnalyticsTypesValues;
    /** context ID used to determine modal header
     * @see useGetModalContextInfo */
    id?: number;
    /** itemId to be used in segment tracking */
    itemId?: number;
    /** optional arguments runAtLoginFunction will be called with, if defined */
    runAtLoginArgs?: any;
    /** optional function that will be run after modal action completes */
    runAtLoginFunction?: (args?: any) => void;
    /** sellerId to be used in segment tracking */
    sellerId?: number;
    /** types used to determine modal header display along with id
     * @see useGetModalContextInfo */
    type?: LoginSignupTypes;
};

export type ModalSlice = {
    addCreditCardModalProvider: PaymentProviders;
    addressId: number;
    alertTypeId: number;
    data: any;
    excludeIsInclude: boolean;
    excludeSellerId: number;
    fromBiddingConsole: boolean;
    isAddCreditCardBiddingConsole: boolean;
    isCheckout: boolean;
    isInstantAutopay: boolean;
    modal: $Values<typeof modalTypes>;
    modalContext: ModalContext | undefined;
    savedSearchId: number;
    searchParams: any;
};

// loginModalContext for openCompleteAccountSignUpModal
export const loginModalContext: ModalContext = { id: 0, type: 'login' };

// reducer
export const defaultModalSlice: ModalSlice = {
    addCreditCardModalProvider: PaymentProviders.Payrix,
    addressId: 0,
    alertTypeId: 0,
    data: null,
    excludeIsInclude: false,
    excludeSellerId: 0,
    fromBiddingConsole: false,
    isAddCreditCardBiddingConsole: false,
    isCheckout: false,
    isInstantAutopay: false,
    modal: modalTypes.NONE,
    modalContext: undefined,
    savedSearchId: 0,
    searchParams: null,
};

export default function reducer(state: ModalSlice = defaultModalSlice, action: any = {}): ModalSlice {
    switch (action.type) {
        case COMPLETE_ACCOUNT_SUCCESS:
        case CREATE_ACCOUNT_SUCCESS:
        case LOGIN_SUCCESS:
            let modal: ModalSlice['modal'];
            if (state.modal === modalTypes.PLACE_ABSENTEE_BID) {
                modal = modalTypes.PLACE_ABSENTEE_BID;
            } else if (state.modal === modalTypes.REGISTER_FOR_AUCTION) {
                modal = modalTypes.REGISTER_FOR_AUCTION;
            } else if (state.modal === modalTypes.COMPLETE_ACCOUNT_SIGNUP) {
                modal = modalTypes.COMPLETE_ACCOUNT_SIGNUP;
            } else {
                modal = modalTypes.NONE;
            }
            return {
                ...state,
                modal: modal,
            };
        case DISMISS_MODAL:
            return {
                ...state,
                data: null,
                isAddCreditCardBiddingConsole: false,
                isCheckout: false,
                isInstantAutopay: false,
                modal: modalTypes.NONE,
                modalContext: { id: 0, type: 'default' },
            };
        case OPEN_ADD_CREDIT_CARD_MODAL:
            return {
                ...state,
                addCreditCardModalProvider: action.payload.addCreditCardModalProvider || PaymentProviders.Payrix,
                isAddCreditCardBiddingConsole: action.payload.isAddCreditCardBiddingConsole,
                isCheckout: action.payload.isCheckout,
                isInstantAutopay: action.payload.isInstantAutopay,
                modal: modalTypes.ADD_CREDIT_CARD,
            };
        case OPEN_BID_LIMIT_EXCEEDED_MODAL:
            return {
                ...state,
                modal: modalTypes.BID_LIMIT_EXCEEDED,
            };
        case OPEN_BUY_NOW_MODAL:
            return {
                ...state,
                modal: modalTypes.BUY_NOW,
            };
        case OPEN_CATALOG_ANNOUNCEMENT_MODAL:
            return {
                ...state,
                modal: modalTypes.CATALOG_ANNOUNCEMENT,
            };
        case OPEN_CLASSIC_BIDDING_CONSOLE_MODAL:
            return {
                ...state,
                modal: modalTypes.CLASSIC_BIDDING_CONSOLE,
            };
        case OPEN_COMPLETE_ACCOUNT_MODAL:
            return {
                ...state,
                modal: modalTypes.ADDRESS,
            };
        case OPEN_COMPLETE_ACCOUNT_SIGNUP_MODAL:
            return {
                ...state,
                modal: modalTypes.COMPLETE_ACCOUNT_SIGNUP,
                modalContext: {
                    eventContext: EventContext.join,
                    id: 0,
                    type: 'default',
                    ...state.modalContext,
                    ...action.payload?.modalContext,
                },
            };
        case OPEN_PURCHASE_PROTECTION_MODAL:
            return {
                ...state,
                modal: modalTypes.PURCHASE_PROTECTION,
            };
        case OPEN_SHIPPING_AND_PAYMENT_MODAL:
            return {
                ...state,
                modal: modalTypes.SHIPPING_AND_PAYMENT,
            };
        case OPEN_SMS_CONFIRMATION_MODAL:
            return {
                ...state,
                alertTypeId: action?.payload,
                modal: modalTypes.SMS_CONFIRMATION,
            };
        case OPEN_DELETE_ACCOUNT_CONFIRMATION_MODAL:
            return {
                ...state,
                data: action.payload,
                modal: modalTypes.DELETE_ACCOUNT_CONFIRMATION,
            };
        case OPEN_RESEND_CONFIRMATION_EMAIL_MODAL:
            return {
                ...state,
                modal: modalTypes.RESEND_CONFIRMATION_EMAIL,
            };
        case OPEN_DOWNLOAD_MOBILE_MODAL:
            return {
                ...state,
                modal: modalTypes.DOWNLOAD_MOBILE,
            };
        case OPEN_HEALTHCHECK_MODAL:
            return {
                ...state,
                modal: modalTypes.HEALTHCHECK,
            };
        case OPEN_INSTALL_FLASH_MODAL:
            return {
                ...state,
                modal: modalTypes.INSTALL_FLASH,
            };
        case OPEN_ON_THE_HUNT_MODAL:
            return {
                ...state,
                modal: modalTypes.ON_THE_HUNT,
            };
        case OPEN_WAITING_FOR_APPROVAL_MODAL:
            return {
                ...state,
                modal: modalTypes.WAITING_FOR_APPROVAL,
            };
        case OPEN_CHANGE_PASSWORD_MODAL:
            return {
                ...state,
                modal: modalTypes.CHANGE_PASSWORD,
            };
        case OPEN_CONSIGNMENT_CONFIRM_MODAL:
            return {
                ...state,
                modal: modalTypes.CONFIRM_CONSIGNMENT,
            };
        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                modal: modalTypes.COMPLETE_ACCOUNT_SIGNUP,
                modalContext: { id: 0, type: 'forgot password' },
            };
        case OPEN_FEEDBACK_MODAL:
            return {
                ...state,
                modal: modalTypes.FEEDBACK,
            };
        case OPEN_LIVE_BID_MODAL:
            return {
                ...state,
                modal: modalTypes.LIVE_BID,
            };
        case OPEN_PHONE_VERIFICATION_MODAL:
            return {
                ...state,
                modal: modalTypes.PHONE_VERIFICATION,
            };
        case OPEN_REPORT_REVIEW_MODAL:
            return {
                ...state,
                modal: modalTypes.REPORT_REVIEW,
            };
        case OPEN_RETRACT_BID_MODAL:
            return {
                ...state,
                modal: modalTypes.RETRACT_BID,
            };
        case OPEN_REVIEWS_MODAL:
            return {
                ...state,
                modal: modalTypes.REVIEWS,
            };
        case OPEN_REGISTER_FOR_CATALOG_MODAL:
            return {
                ...state,
                fromBiddingConsole: action.payload.fromBiddingConsole,
                isInstantAutopay: action.payload.isInstantAutopay,
                modal: modalTypes.REGISTER_FOR_AUCTION,
                modalContext: action.payload.modalContext,
            };
        case OPEN_SEND_SELLER_CONSIGNMENT_MESSAGE_MODAL:
            return {
                ...state,
                modal: modalTypes.SEND_SELLER_CONSIGNMENT_MESSAGE,
            };
        case OPEN_SEND_SELLER_MESSAGE_MODAL:
            return {
                ...state,
                modal: modalTypes.SEND_SELLER_MESSAGE,
            };
        case OPEN_SEND_SELLER_PHOTO_REQUEST_MODAL:
            return {
                ...state,
                modal: modalTypes.SEND_SELLER_PHOTO_REQUEST,
            };
        case OPEN_TAX_EXEMPT_MODAL:
            return {
                ...state,
                modal: modalTypes.TAX_EXEMPT,
            };
        case OPEN_EXCLUDE_HOUSE_MODAL:
            return {
                ...state,
                excludeIsInclude: action?.payload?.isInclude,
                excludeSellerId: action?.payload?.sellerId,
                modal: modalTypes.EXCLUDE_HOUSE,
            };
        case OPEN_CONFIRM_UNFOLLOW_SEARCH_MODAL:
            return {
                ...state,
                modal: modalTypes.CONFIRM_UNFOLLOW_SEARCH,
                savedSearchId: action?.payload?.savedSearchId,
            };
        case SET_LOGIN_SIGNUP_TYPE:
            return {
                ...state,
                modalContext: { ...action?.payload },
            };
        case OPEN_CLEAR_CACHE_MODAL:
            return {
                ...state,
                modal: modalTypes.CLEAR_CACHE,
            };
        case OPEN_MODAL:
            return {
                ...state,
                data: action.payload.data,
                modal: action.payload.modalType,
            };
        case modalTypes.BID_CONSOLE_EXPLAINER:
            return {
                ...state,
                modal: modalTypes.BID_CONSOLE_EXPLAINER,
            };
        case SET_MODAL_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case OPEN_PLACE_ABSENTEE_BID:
            return {
                ...state,
                modal: modalTypes.PLACE_ABSENTEE_BID,
                modalContext: { ...action.payload?.modalContext },
            };
        case OPEN_LIVE_SHIPPING_DEADLINE_MODAL:
            return {
                ...state,
                modal: modalTypes.LIVE_SHIPPING_DEADLINE,
            };
        case OPEN_BUY_NOW_INQUIRY_MODAL: {
            return {
                ...state,
                data: action.payload,
                modal: modalTypes.BUY_NOW_INQUIRY,
            };
        }
        case ROUTER_LOCATION_CHANGE:
            // the client has manually redirected with browser back/forward buttons, so we need to ensure the modals are closed
            return defaultModalSlice;
        default:
            return state;
    }
}

/* ACTION CREATORS */
export const completeLoginModal = () => ({
    type: COMPLETE_LOGIN_MODAL,
});

export const dismissModal = () => ({
    type: DISMISS_MODAL,
});

type AddCreditCardModalOptions = {
    isAddCreditCardBiddingConsole?: boolean;
    isCheckout?: boolean;
    isInstantAutopay?: boolean;
};

export const openAddCreditCardModal = (
    addCreditCardModalProvider: number,
    {
        isAddCreditCardBiddingConsole = false,
        isCheckout = false,
        isInstantAutopay = false,
    }: AddCreditCardModalOptions = {}
) => ({
    payload: {
        addCreditCardModalProvider,
        isAddCreditCardBiddingConsole,
        isCheckout,
        isInstantAutopay,
    },
    type: OPEN_ADD_CREDIT_CARD_MODAL,
});

export const openAddressModal = (data: AddressModalData) => ({
    payload: {
        data: data,
        modalType: modalTypes.ADDRESS,
    },
    type: OPEN_MODAL,
});

export const openBidLimitExceededModal = (catalogId: number) => ({
    payload: catalogId,
    type: OPEN_BID_LIMIT_EXCEEDED_MODAL,
});

export const openBuyNowModal = ({ itemId, modalType, offerPrice }: any) => ({
    payload: { itemId, modalType, offerPrice },
    type: OPEN_BUY_NOW_MODAL,
});

export const openChangePasswordModal = () => ({
    type: OPEN_CHANGE_PASSWORD_MODAL,
});

export const openCompleteAccountModal = () => ({
    type: OPEN_COMPLETE_ACCOUNT_MODAL,
});

export const openConfirmConsignmentModal = () => ({
    type: OPEN_CONSIGNMENT_CONFIRM_MODAL,
});

export const openFeedbackModal = () => ({
    type: OPEN_FEEDBACK_MODAL,
});

export const openForgotPasswordModal = () => {
    return openCompleteAccountSignUpModal({ id: 0, type: 'forgot password' });
};

export const openForgotPasswordModalDelay = () => async (dispatch: AppDispatch) => {
    setTimeout(() => dispatch(openForgotPasswordModal()), 500);
};

export const openLoginModal = (modalContext?: ModalContext) => {
    return openCompleteAccountSignUpModal({
        ...loginModalContext,
        ...modalContext,
    });
};

export const openOnTheHuntModal = () => ({
    type: OPEN_ON_THE_HUNT_MODAL,
});

export const openExcludeHouseModal = (sellerId: number, isInclude?: boolean) => async (dispatch: AppDispatch) => {
    await dispatch(fetchSellersIfNeeded([sellerId]));
    dispatch({
        payload: { isInclude, sellerId },
        type: OPEN_EXCLUDE_HOUSE_MODAL,
    });
};

export const openClearCacheModal = () => ({
    type: OPEN_CLEAR_CACHE_MODAL,
});

export type OpenRegisterForAuctionModalParams = {
    catalogId: number;
    fromBiddingConsole?: boolean;
    isInstantAutopay?: boolean;
    itemId?: number;
    modalContext?: ModalContext;
};
export const openRegisterForAuctionModal = ({
    catalogId,
    fromBiddingConsole = false,
    isInstantAutopay = false,
    itemId = 0,
    modalContext,
}: OpenRegisterForAuctionModalParams) => ({
    payload: {
        catalogId,
        fromBiddingConsole,
        isInstantAutopay,
        itemId,
        modalContext,
    },
    type: OPEN_REGISTER_FOR_CATALOG_MODAL,
});

export const openRetractBidModal = (itemId: number) => ({
    payload: { itemId },
    type: OPEN_RETRACT_BID_MODAL,
});

export const openSendSellerConsignmentMessageModal = (messageDetails: any, sellerId: number, sellerName: string) => ({
    payload: { messageDetails, sellerId, sellerName },
    type: OPEN_SEND_SELLER_CONSIGNMENT_MESSAGE_MODAL,
});

export const openSendSellerMessageModal = (
    messageDetails: any,
    sellerId: number,
    sellerName: string,
    subject: string,
    title: string = ''
) => ({
    payload: { messageDetails, sellerId, sellerName, subject, title },
    type: OPEN_SEND_SELLER_MESSAGE_MODAL,
});

export type OpenSendSellerPhotoRequestModalParams = {
    imageGalleryIndex?: number;
    imageGalleryLength?: number;
    messageDetails: SendSellerMessageSlice['messageDetails'];
    requestType: PhotoRequestType;
    sellerId: number;
    sellerName: string;
};
export const openSendSellerPhotoRequestModal = ({
    imageGalleryIndex,
    imageGalleryLength,
    messageDetails,
    requestType,
    sellerId,
    sellerName,
}: OpenSendSellerPhotoRequestModalParams) => ({
    payload: {
        imageGalleryIndex,
        imageGalleryLength,
        messageDetails,
        requestType,
        sellerId,
        sellerName,
    },
    type: OPEN_SEND_SELLER_PHOTO_REQUEST_MODAL,
});

export const openShippingAndPaymentModal = () => ({
    type: OPEN_SHIPPING_AND_PAYMENT_MODAL,
});

export const openPurchaseProtectionModal = () => ({
    type: OPEN_PURCHASE_PROTECTION_MODAL,
});

export const openCatalogAnnouncementModal = (catalogId: number): OpenCatalogAnnouncementModalAction => ({
    error: false,
    payload: { catalogId },
    type: OPEN_CATALOG_ANNOUNCEMENT_MODAL,
});

export const openWaitingForApprovalModal = (catalogId: number, hideUrgentMessages: boolean = false) => {
    return {
        payload: { catalogId, hideUrgentMessages },
        type: OPEN_WAITING_FOR_APPROVAL_MODAL,
    };
};

export const openReportReviewModal = (reviewId: number) => ({
    payload: reviewId,
    type: OPEN_REPORT_REVIEW_MODAL,
});

export const openReviewsModal = (sellerId: number) => ({
    payload: sellerId,
    type: OPEN_REVIEWS_MODAL,
});

export const openSMSConfirmationModal = (alertTypeId: number) => ({
    payload: alertTypeId,
    type: OPEN_SMS_CONFIRMATION_MODAL,
});

export const openDeleteAccountConfirmationModal = (data?: { email: string }) => ({
    payload: data,
    type: OPEN_DELETE_ACCOUNT_CONFIRMATION_MODAL,
});

export const openResendConfirmationEmailModal = () => ({
    type: OPEN_RESEND_CONFIRMATION_EMAIL_MODAL,
});

export const openTaxExemptModal = () => ({
    type: OPEN_TAX_EXEMPT_MODAL,
});

export const openAddressVerifyModal = (data) => ({
    payload: {
        data: data,
        modalType: modalTypes.ADDRESS_VERIFY_MODAL,
    },
    type: OPEN_MODAL,
});

export const setModalContext = (context: ModalContext) => {
    return {
        payload: context,
        type: SET_LOGIN_SIGNUP_TYPE,
    };
};

export const openZipModal = () => ({
    payload: { modalType: modalTypes.ZIP_INFO_MODAL },
    type: OPEN_MODAL,
});

export const openConfirmUnfollowSearchModal = (savedSearchId: number) => ({
    payload: { modalType: modalTypes.CONFIRM_UNFOLLOW_SEARCH, savedSearchId },
    type: OPEN_CONFIRM_UNFOLLOW_SEARCH_MODAL,
});

export const openBidConsoleExplainerModal = () => ({
    type: modalTypes.BID_CONSOLE_EXPLAINER,
});

export const setModalData = (data: any) => ({
    payload: data,
    type: SET_MODAL_DATA,
});

interface OpenPlaceAbsenteeBidParams {
    bidAmount?: number;
    isInstantAutopay?: boolean;
    itemId: number;
    modalContext?: ModalContext;
}

export const openPlaceAbsenteeBidModal = ({
    bidAmount,
    isInstantAutopay,
    itemId,
    modalContext,
}: OpenPlaceAbsenteeBidParams) => ({
    payload: {
        bidAmount,
        isInstantAutopay,
        itemId,
        modalContext,
    },
    type: OPEN_PLACE_ABSENTEE_BID,
});

export const openCompleteAccountSignUpModal = (modalContext?: ModalContext) => ({
    payload: { modalContext },
    type: OPEN_COMPLETE_ACCOUNT_SIGNUP_MODAL,
});

export const openPhoneVerificationModal = () => ({
    type: OPEN_PHONE_VERIFICATION_MODAL,
});

export const openLiveShippingDeadlineModal = () => ({
    type: OPEN_LIVE_SHIPPING_DEADLINE_MODAL,
});

export type WonItemsPageGetHelpModalData = {
    catalogId: number;
    invoiceId: number;
    invoiceStatus: string | JSX.Element;
    isLivePayments: boolean;
    isLiveShipping: boolean;
    isPaid: boolean;
    itemCount: number;
    itemId: number;
    sellerId: number;
};

export type ItemPageGetHelpModalData = {
    catalogId: number;
    isLivePayments: boolean;
    isLiveShipping: boolean;
    itemId: number;
    sellerId: number;
};

export type GetHelpModalData = ItemPageGetHelpModalData | WonItemsPageGetHelpModalData;

export type OpenGetHelpModalParams = {
    data: {
        modalData: GetHelpModalData;
        modalVariant: GetHelpModalTypes;
    };
};

export const openGetHelpModal =
    ({ data }: OpenGetHelpModalParams) =>
    (dispatch: AppDispatch) => {
        dispatch(trackGetHelpViewed());
        dispatch({
            payload: {
                data,
                modalType: modalTypes.GET_HELP,
            },
            type: OPEN_MODAL,
        });
    };

export type OpenBuyNowInquiryModalParams = { itemId: number };

export const openBuyNowInquiryModal = ({ itemId }: OpenBuyNowInquiryModalParams) => ({
    payload: { itemId },
    type: OPEN_BUY_NOW_INQUIRY_MODAL,
});
