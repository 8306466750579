import { CompleteAccountModalContext, ModalContext } from './modal';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { modalTypes, PaymentProviders } from '@/enums';

const modalSelector = ({ modal }: GlobalState) => modal;
export const getInstantAutopay = createSelector(modalSelector, (modal) => modal.isInstantAutopay);

export const getOpenModal = createSelector(modalSelector, (modal) => modal.modal || modalTypes.NONE);
export const getOpenModalData = createSelector(modalSelector, (modal) => modal.data);

export const getAddCreditCardModalProvider = createSelector(modalSelector, (state) => state.addCreditCardModalProvider);
export const getIsValidPaymentProvider = createSelector(
    modalSelector,
    (state) =>
        state.addCreditCardModalProvider === PaymentProviders.Payrix ||
        state.addCreditCardModalProvider === PaymentProviders.Paysafe
);
export const getIsAddCreditCardBiddingConsole = createSelector(
    modalSelector,
    (state) => state.isAddCreditCardBiddingConsole
);
export const getIsCheckout = createSelector(modalSelector, (state) => state.isCheckout);

export const getSearchParams = createSelector(modalSelector, (state) => state.searchParams);
export const getAddressID = createSelector(modalSelector, (state) => state.addressId);
export const getAlertTypeId = createSelector(modalSelector, (state) => state.alertTypeId);

export const getExcludeModalSellerId = createSelector(modalSelector, (state) => state.excludeSellerId);
export const getExcludeIsInclude = createSelector(modalSelector, (state) => state.excludeIsInclude);

export const getConfirmUnfollowSearchId = createSelector(modalSelector, (state) => state.savedSearchId);

export const getModalContext: (state: GlobalState) => ModalContext = createSelector(
    modalSelector,
    (state) => state.modalContext || { id: 0, type: CompleteAccountModalContext.Default }
);

export const getFromBiddingConsole = createSelector(modalSelector, (state) => state.fromBiddingConsole);
