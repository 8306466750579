import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { DISMISS_MODAL, OPEN_BUY_NOW_MODAL } from './actions';
import { getUserData } from '@/redux/modules/account/user/user.selectors';
import { modalTypes } from '@/enums';
import { submitStartConversation } from './sendSellerMessage';

/* Action Types */
const MAKE_OFFER_FAIL = 'la/ui/makeOffer/FAIL';
const MAKE_OFFER_REQUEST = 'la/ui/makeOffer/REQUEST';
const MAKE_OFFER_SUCCESS = 'la/ui/makeOffer/SUCCESS';

// reducer
export type MakeOfferSlice = {
    error: boolean;
    errorMessage: string | null;
    itemId?: number;
    modalType: (typeof modalTypes)[keyof typeof modalTypes];
    offerPrice: number;
    submitted: boolean;
    success: boolean;
};

export const defaultMakeOfferSlice: MakeOfferSlice = {
    error: false,
    errorMessage: null,
    itemId: undefined,
    modalType: modalTypes.BUY_NOW,
    offerPrice: 0,
    submitted: false,
    success: false,
};

export default function reducer(state: MakeOfferSlice = defaultMakeOfferSlice, action: any = {}): MakeOfferSlice {
    switch (action.type) {
        case MAKE_OFFER_REQUEST:
            return {
                ...state,
                error: false,
                errorMessage: null,
                submitted: true,
                success: false,
            };
        case MAKE_OFFER_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        case MAKE_OFFER_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                submitted: false,
                success: false,
            };
        case OPEN_BUY_NOW_MODAL:
            return {
                ...state,
                itemId: action.payload.itemId,
                modalType: action.payload.modalType,
                offerPrice: action.payload.offerPrice,
            };
        case DISMISS_MODAL:
            return defaultMakeOfferSlice;
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const getUiMakeOffer = createSelector(stateSelector, (state) => state.makeOffer);

/* ACTION CREATORS */
export const submitMakeOffer =
    ({
        address,
        buyNow,
        catalogId,
        currency,
        email,
        formatNumber,
        highEistimate,
        itemId,
        lowEistimate,
        message,
        offer,
        phoneNumber,
        premium,
        saledate,
        sellerId,
        shipping,
        title,
    }: any) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const uiState = getUiMakeOffer(state);
            const userData = getUserData(state);
            const formatCurrencyObject = {
                currency,
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
                style: 'currency',
            };
            const offerMessage = buyNow === offer ? 'wants to buy your item' : 'sent you an offer';
            const messageText = `
${userData.firstName} ${userData.lastName} (${address.city}, ${address.state}, ${address.country}) ${offerMessage}!
Please reply to this message to accept or reject this offer.
\n
Email: ${email}
Phone Number: ${phoneNumber}
\n
Offer: ${formatNumber(offer, formatCurrencyObject)}\n
Buy Now Price: ${formatNumber(buyNow, formatCurrencyObject)}\n
Estimate: ${formatNumber(lowEistimate, formatCurrencyObject)} - ${formatNumber(highEistimate, formatCurrencyObject)}\n
\n
Message: ${message}\n
\n
Address:
${userData.firstName} ${userData.lastName}
${address.address}
${address.address2}
${address.city}, ${address.state} ${address.zip}
${address.country}
\n
Reference:
Bidder ID: ${userData.bidderId}\n
Item ID: ${itemId}\n
Sale Date: ${saledate}\n
Catalog ID: ${catalogId}\n
Premium: ${formatNumber(premium, formatCurrencyObject)}\n
Shipping: ${typeof shipping === 'number' ? formatNumber(shipping, formatCurrencyObject) : shipping}\n`;

            const subjectStart = uiState.modalType === modalTypes.BUY_NOW ? 'Buy Now' : 'Make An Offer';
            const subject = `${subjectStart} - ${title}`;
            const messageDetails = {
                catalogId,
                itemId,
                location: null,
                message: messageText,
                sellerId,
                subject,
            };

            dispatch({
                payload: messageDetails,
                type: MAKE_OFFER_REQUEST,
            });
            await dispatch(submitStartConversation(messageDetails));

            dispatch({
                meta: { actionTime: Date.now() },
                payload: itemId,
                type: MAKE_OFFER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { itemId },
                payload: error,
                type: MAKE_OFFER_FAIL,
            });
        }
    };
