import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import {
    LIVE_BID_ACCEPTED,
    LIVE_BID_RETRACTED,
    LIVE_LOT_PASSED,
    LIVE_LOT_REOPENED,
    LIVE_LOT_SOLD,
    LIVE_LOT_UNSOLD,
    LIVE_NEXT_LOT_LOADED,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
} from './actions';

type LiveItemStatus = {
    bidderLiveBid: number;
    catalogId?: number;
    itemId?: number;
    open: boolean;
};

export type LiveItemStatusSlice = {
    readonly byId: {
        [itemId: number]: LiveItemStatus;
    };
};

// reducer
export const defaultLiveItemStatusSlice: LiveItemStatusSlice = {
    byId: {},
};

const closeAllLots = (oldState, catalogId) => {
    const lots = {};
    Object.keys(oldState).forEach((cur) => {
        const lot = { ...oldState[cur] };
        if (lot.catalogId === catalogId) {
            lot.open = false;
        }
        lots[lot.itemId] = lot;
    });
    return lots;
};

export default function reducer(
    state: LiveItemStatusSlice = defaultLiveItemStatusSlice,
    action: any = {}
): LiveItemStatusSlice {
    let existing: LiveItemStatusSlice['byId'];
    let catalogId: number;
    let itemId: number;

    switch (action.type) {
        case LIVE_BID_ACCEPTED:
            catalogId = action.payload.catalogId;
            itemId = action.payload.itemId;
            existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: action.payload.myBid ? action.payload.amount : 0,
                catalogId,
                itemId,
                open: true,
            };
            return {
                ...state,
                byId: existing,
            };
        case LIVE_BID_RETRACTED:
        case LIVE_LOT_REOPENED:
        case LIVE_NEXT_LOT_LOADED:
            catalogId = action.payload.catalogId;
            itemId = action.payload.itemId;
            existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: true,
            };
            return {
                ...state,
                byId: existing,
            };
        case LIVE_LOT_PASSED:
        case LIVE_LOT_SOLD:
        case LIVE_LOT_UNSOLD:
            catalogId = action.payload.catalogId;
            itemId = action.payload.itemId;
            existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: false,
            };
            return {
                ...state,
                byId: existing,
            };
        case LOAD_LIVE_CATALOG_STATUS_SUCCESS:
            const currentItem = action.payload.currentItem || {};
            catalogId = action.payload.catalogId;
            itemId = currentItem.itemId;
            const lotStatus = currentItem.lotStatus;

            if (!itemId) {
                return state;
            }

            existing = closeAllLots(state.byId, catalogId);
            existing[itemId] = {
                bidderLiveBid: 0,
                catalogId,
                itemId,
                open: lotStatus === 'open',
            };
            return {
                ...state,
                byId: existing,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const liveItemStatusSlice = createSelector(stateSelector, (state) => state.liveItemStatus);
const idSelector = (_: GlobalState, id: number) => id;
const idsSelector = (state: GlobalState, ids: number[]) => ids;

const byIdSelector = createSelector(liveItemStatusSlice, (state) => state.byId);

export const getLiveItemStatus = createSelector(
    [byIdSelector, idSelector],
    (byId, id) =>
        byId[id] || {
            bidderLiveBid: 0,
            open: false,
        }
);

export const getLiveItemStatusOpen = createSelector(getLiveItemStatus, (status) => status.open);

export const getLiveItemsStatus = createSelector([byIdSelector, idsSelector], (byId, itemIds) =>
    itemIds.map((itemId: number) => byId[itemId]).filter((item) => Boolean(item))
);
