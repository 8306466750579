import * as configApi from '../api/config';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchCatalogPresenceById, fetchLiveCatalogStatus } from '@/redux/api/liveCatalogStatus';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment, getPubSubMessagingProviderKeys } from './config';
import {
    LIVE_UPDATE_CATALOG_OCCUPANCY,
    LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION,
    LIVE_UPDATE_TIMESTAMP,
    LIVE_UPDATE_TIMESTAMP_ACTION,
    LOAD_LIVE_CATALOG_STATUS_FAIL,
    LOAD_LIVE_CATALOG_STATUS_REQUEST,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION,
    LOAD_LIVE_CATALOGS_CONFIG_SUCCESS,
    LOAD_LIVE_CATALOGS_CONFIG_SUCCESS_ACTION,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

export type LiveCatalogStatusSlice = {
    readonly loaded: {
        [catalogId: number]: number;
    };
    readonly loading: number[];
};

/* reducer */
export const defaultLiveCatalogStatusSlice: LiveCatalogStatusSlice = {
    loaded: {},
    loading: [],
};

export default function reducer(
    state: LiveCatalogStatusSlice = defaultLiveCatalogStatusSlice,
    action: any = {}
): LiveCatalogStatusSlice {
    let loaded: LiveCatalogStatusSlice['loaded'];
    let loading: LiveCatalogStatusSlice['loading'];
    let time: number;

    switch (action.type) {
        case LOAD_LIVE_CATALOG_STATUS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.catalogId]),
            };
        case LOAD_LIVE_CATALOG_STATUS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_LIVE_CATALOG_STATUS_SUCCESS:
            loaded = cloneDeep(state.loaded);
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;
            loaded[action.meta.catalogId] = time;
            return {
                ...state,
                loaded,
                loading: difference(loading, [action.payload.catalogId]),
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const liveCatalogStatusSlice = createSelector(stateSelector, (state) => state.liveCatalogStatus);

const idSelector = (_: GlobalState, id: number) => id;

export const loadedSelector = createSelector(liveCatalogStatusSlice, (state) => state.loaded);

export const loadingSelector = createSelector(liveCatalogStatusSlice, (state) => state.loading);

export const getLoadTimeForLiveCatalogStatus = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

export const isLiveCatalogStatusLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

const shouldFetchLiveCatalogStatus = (state, catalogId, sellerId) => {
    if (!catalogId || !sellerId) {
        return false;
    }
    const loaded = getLoadTimeForLiveCatalogStatus(state, catalogId);
    if (loaded) {
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isLiveCatalogStatusLoading(state, catalogId);
    return !loading;
};

/* ACTION CREATORS */
export const loadLiveCatalogStatus =
    (catalogId: number, sellerId: number, lotIds?: number[]) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            let bidderId = getBidderId(state);
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: catalogId,
                type: LOAD_LIVE_CATALOG_STATUS_REQUEST,
            });

            /**
             * Get the current auction state from auction-engine
             */
            const response = await fetchLiveCatalogStatus({
                authToken,
                catalogId,
                deployment,
                lotIds,
                sellerId,
            });

            if (response.error === true) {
                const loadLiveCatalogStatusSuccessAction: LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION = {
                    error: false,
                    meta: { actionTime: Date.now(), bidderId, catalogId },
                    payload: {
                        status: 'notLoaded',
                    },
                    type: LOAD_LIVE_CATALOG_STATUS_SUCCESS,
                };
                return dispatch(loadLiveCatalogStatusSuccessAction);
            } else {
                const loadLiveCatalogStatusAction: LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION = {
                    error: false,
                    meta: { actionTime: Date.now(), bidderId, catalogId },
                    payload: response.data,
                    type: LOAD_LIVE_CATALOG_STATUS_SUCCESS,
                };
                if (response?.data?.status === 'live') {
                    const liveUpdateTimestampAction: LIVE_UPDATE_TIMESTAMP_ACTION = {
                        error: false,
                        payload: {
                            catalogId,
                            timestamp: Date.now(),
                        },
                        type: LIVE_UPDATE_TIMESTAMP,
                    };
                    dispatch(liveUpdateTimestampAction);
                }
                return dispatch(loadLiveCatalogStatusAction);
            }
        } catch (error) {
            return dispatch({
                error: true, // TODO: this isn't used
                meta: { catalogId },
                payload: error,
                type: LOAD_LIVE_CATALOG_STATUS_FAIL,
            });
        }
    };

export const fetchCatalogPresence = (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const { subscribeKey } = getPubSubMessagingProviderKeys(state);

        if (!subscribeKey) {
            return Promise.resolve();
        }

        const { occupancy: bidderCount } = await fetchCatalogPresenceById({
            catalogId,
            subscribeKey,
        });

        dispatch({
            payload: { bidderCount, catalogId },
            type: LIVE_UPDATE_CATALOG_OCCUPANCY,
        } as LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION);
    } catch (error) {
        // do nothing
    }
};

export const fetchLiveCatalogStatusIfNeeded =
    (catalogId: number, sellerId: number, force: boolean = false) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        if (
            (Boolean(catalogId) && Boolean(sellerId) && force) ||
            shouldFetchLiveCatalogStatus(getState(), catalogId, sellerId)
        ) {
            return dispatch(loadLiveCatalogStatus(catalogId, sellerId));
        }
        return Promise.resolve();
    };

/**
 * Gets the current pub/sub provider + pubnub pub/sub keys and adds them to the config
 */
export const fetchLiveCatalogsConfig = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    const liveAuctionConfig = await configApi.fetchLiveAuctionsConfig({
        authToken,
        deployment,
    });

    dispatch({
        payload: liveAuctionConfig,
        type: LOAD_LIVE_CATALOGS_CONFIG_SUCCESS,
    } as LOAD_LIVE_CATALOGS_CONFIG_SUCCESS_ACTION);
};
